import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import IO from "../../../components/pose/IO";
import StartButton from "../../../components/StartButton";
import color from "../../../common/styles/color";
import MEDIA from "../../../common/styles/media";
import { UpPose } from "../../../components/pose/Poses";
import "./StudentHero.css";
import LargeButton from "../../../components/LargeButton";
import WhiteButton from "../../../components/WhiteButton";

const StudentHero = ({ data }) => {
  return (
    <IO>
      {({ isVisible, hasBeenVisible }) => (
        <Wrapper>
          <UpPose pose={isVisible || hasBeenVisible ? "visible" : "hidden"}>
            <Grid>
              {data.hero.map((item, i) => (
                <TextColumn key={i} color={color[item.color]}>
                  {/*<Subtitle>{item.subtitle}</Subtitle>
                  <Title>{item.title}</Title>
              <Description>{item.description}</Description>*/}
                  {!item.image.childImageSharp ||
                    item.image.extension === "svg" ? (
                      <Image
                        src={item.image.publicURL}
                        alt={item.title}
                      />
                    ) : (
                      <Image
                        src={item.image.publicURL}
                        alt={item.title}
                      />
                    )}
                </TextColumn>
              ))}
            </Grid>
          </UpPose>
        </Wrapper>
      )}
    </IO>
    /*<IO>
      {({ isVisible, hasBeenVisible }) => (
        <>
          <div className="BackgroundTwinkle">
            <div id="stars"></div>
            <div id="stars2"></div>
          </div>
          <>
            <>
              <Wrapper>
                <div className="StudentBackground">
                  <UpPose
                    pose={isVisible || hasBeenVisible ? "visible" : "hidden"}
                  >
                    <div className="TopBannerContainer">
                      <div className="TopBannerText">
                        <div className="TopBannerTitle">
                          The learning you want.
                        </div>
                        <div className="TopBannerDescription">
                          Everyone learns in different ways and at different
                          paces.
                        </div>
                        <div className="TopBannerButtonContainer">
                          <WhiteButton href="localhost:3000">
                            Join Beta
                          </WhiteButton>
                        </div>
                      </div>
                    </div>
                  </UpPose>
                </div>
              </Wrapper>
            </>
          </>
        </>
      )}
    </IO>*/
  );
};

const HeroWithQuery = props => (
  <StaticQuery
    query={graphql`
      query ParentsHeroQuery {
        parentsYaml {
          hero {
            subtitle
            color
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
              extension
            }
          }
        }
      }
    `}
    render={({ parentsYaml }) => (
      <StudentHero data={parentsYaml} {...props} />
    )}
  />
);

export default HeroWithQuery;


/*
<StaticQuery
    query={graphql`
      query FeatureQuery {
        homeYaml {
          features {
            subtitle
            color
            title
            description
          }
        }
      }
    `}

    query={graphql`
      query parentsHeroQuery {
        parentsYaml {
          header {
            title
            highlight
            description
            action
            comment
            image {
              childImageSharp {
                fluid(maxWidth: 1500, quality: 95) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
              publicURL
              extension
            }
          }
        }
      }
    `}
*/

/*const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: grid;
  min-height: 600px;
  max-height: 65px;
  height: 80vh;
  justify-content: space-between;
  grid-template-columns: 550px 3fr;
  grid-gap: 4rem;
  overflow: hidden;
  margin-bottom: -100px;
  ${MEDIA.MOBILE`
    grid-template-columns: 100%;
    min-height: 600px;
  `};
`;

const Text = styled.div`
  margin: 14% 10% 10% 18%;
  ${MEDIA.TABLET`
    margin: 10vw 10vw 10vw 12vw;
  `};
  ${MEDIA.MOBILE`
    margin: 12vw 10vw 10vw 10vw;
  `};
`;
const Title = styled.h1`
  color: ${color.black};
  font-size: 45px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  ${MEDIA.MOBILE`
    font-size: 65px;
    letter-spacing: -2.8px;
  `};
`;

const Highlight = styled.h1`
  color: ${color.purple};
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.1;
  ${MEDIA.MOBILE`
    font-size: 65px;
    letter-spacing: -2.8px;
  `};
`;

const Description = styled.div`
  margin: 10px 0px 20px;
  line-height: 1.6;
  letter-spacing: -0.7px;
  font-size: 22px;
  ${MEDIA.MOBILE`
    font-size: 18px;
    letter-spacing: -0.6px;
  `};
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0px;
  z-index: 999999;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 100%;
  }
  a {
    font-size: 22px;
    padding: 10px 20px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
  }
  ${MEDIA.MOBILE`
      a {
      font-size: 15px;
    }
  `}
`;

const Comment = styled.p`
  margin-top: 20px;
  font-size: 15px;
  color: ${color.blackLight};
  letter-spacing: -0.4px;
  ${MEDIA.MOBILE`
    font-size: 13px;
    letter-spacing: -0.3px;
  `};
`;

const Figure = styled.figure`
  width: 130%;
  min-width: 980px;
  margin-top: 20px;
  ${MEDIA.TABLET`
    display: none;
  `};
`;
*/
/*export const Wrapper = styled.div`
  background: ${color.darkBackground};
  display: flex;
  justify-content: center;
  padding: 20px 0;
  overflow: hidden;
  z-index: 100;
`;

export const Grid = styled.div`
  max-width: 2000px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${MEDIA.TABLET`
    grid-template-columns: 1fr 1fr;
  `};
  grid-gap: 48px;
  margin-left: 20px;
  margin-right: 20px;
  ${MEDIA.TABLET`
    margin: 0vw 10vw;
  `};
  ${MEDIA.MOBILE`
    margin: 0vw 10vw;
  `};
`;

export const TextColumn = styled.div`
  align-items: left;
  justify-content: top;
  display: flex;
  flex-flow: column;
  padding: 20px;
  border-radius: 10px;
  //background-image: ${props => props.color};
  background: #fff;
  width: 250px;
`;

export const Subtitle = styled.h2`
  color: ${props => props.color};
  margin: 10px 0;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  color: #fff;
  svg {
    margin-right: 3px;
  }
`;

export const Title = styled.h3`
  //max-width: 380px;
  margin: 0px 0 12px 0;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #fff;
  ${MEDIA.DESKTOP`
    font-size: 20px;
    letter-spacing: -1.1px;
  `};
  ${MEDIA.MOBILE`
    font-size: 28px;
    letter-spacing: -1px;
  `};
`;

export const Description = styled.p`
  max-width: 250px;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0px;
  color: #fff;
  ${MEDIA.DESKTOP`
    font-size: 18px;
  `};
  ${MEDIA.MOBILE`
    font-size: 17px;
  `};
`;
*/
export const Wrapper = styled.div`
background: ${color.darkBackground};
  background: ${color.greyBackground};
  display: flex;
  justify-content: center;
  padding: 40px 0;
  overflow: hidden;
  z-index: 100;
`;

export const Grid = styled.div`
  max-width: 2000px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  ${MEDIA.TABLET`
    grid-template-columns: 1fr 1fr;
  `};
  grid-gap: 48px;
  margin-left: 20px;
  margin-right: 20px;
  ${MEDIA.TABLET`
    margin: 0vw 10vw;
  `};
  ${MEDIA.MOBILE`
    margin: 0vw 10vw;
  `};
`;

export const TextColumn = styled.div`
  align-items: left;
  justify-content: top;
  display: flex;
  flex-flow: column;
  width: 300px;
  background: #fff;
  border-radius: 10px;
`;

export const Subtitle = styled.h2`
  color: ${props => props.color};
  margin: 10px 0;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: -0.4px;
  svg {
    margin-right: 3px;
  }
`;

export const Title = styled.h3`
  max-width: 380px;
  margin: 0px 0 10px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -1.2px;
  ${MEDIA.DESKTOP`
    font-size: 20px;
    letter-spacing: -1.1px;
  `};
  ${MEDIA.MOBILE`
    font-size: 28px;
    letter-spacing: -1px;
  `};
`;

export const Description = styled.p`
  max-width: 250px;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: -0.5px;
  ${MEDIA.DESKTOP`
    font-size: 18px;
  `};
  ${MEDIA.MOBILE`
    font-size: 17px;
  `};
`;


export const Image = styled.img`
  height: 200px
  //width: 280px;
`;
