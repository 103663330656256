import React from "react";

import Layout from "../../components/Layout";
import Parents from "../../epics/parents/Parents";

const ParentsPage = () => (
  <Layout>
    {/*<Parents />*/}
  </Layout>
);

export default ParentsPage
